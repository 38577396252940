/* eslint-disable id-length */
import { useTranslate } from 'react-admin';
import {
  AFFILIATION_TYPES,
  APPLICATION_TYPES,
  COMPANY_PLAN_TYPES,
  CORPORATIVE_PLAN_TYPES,
  DOCUMENT_TYPES,
  HIRING_MODE_TYPES,
  INCREASE_PLAN_ALLOWED_TYPES,
  IS_PAYMENT_RESPONSIBLE_TYPES,
  PLAN_TYPES,
  APPLICATION_SUBTYPES
} from '~constants/affiliations';
import { ROLE_TYPES, PERMISO_OSDE_CHOICES, PERMISO_BINARIA_CHOICES } from '~constants/promoters';
import { InputSelectorItemType, InputSelectorListType, newInputSelectorItem } from '~components/InputSelect';
import { FILIALES_LIST } from '~constants/filiales';
import { CAP_LIST } from '~constants/filialesCaps';

export enum CompanyPlanChoicesTypes {
  Full,
  NotP8360AndP8430AndP8260
}
const companyPlanChoicesContainer: { [key: string]: InputSelectorListType } = {};

const useListProviders = () => {
  const t = useTranslate();

  const documentChoices = Object.values(DOCUMENT_TYPES)
    .filter(type => type !== 'pas')
    .map(type => newInputSelectorItem(type, t(`resources.affiliations.fields.documentTypes.${type}`)));

  const affiliationTypeChoices = Object.values(AFFILIATION_TYPES).map(type =>
    newInputSelectorItem(type, t(`resources.affiliations.fields.affiliationTypes.${type}`))
  );

  const applicationTypeChoices = Object.values(APPLICATION_TYPES).map(type =>
    newInputSelectorItem(type, t(`resources.affiliations.fields.applicationTypes.${type}`))
  );

  const applicationSubtypeChoices = Object.values(APPLICATION_SUBTYPES).map(type =>
    newInputSelectorItem(type, t(`resources.affiliations.fields.applicationSubtypes.${type}`))
  );

  const planTypeChoices = Object.values(PLAN_TYPES).map(type =>
    newInputSelectorItem(type, t(`resources.affiliations.fields.planTypes.${type}`))
  );

  const hiringModeChoices = Object.values(HIRING_MODE_TYPES).map(type =>
    newInputSelectorItem(type, t(`resources.affiliations.fields.hiringModeTypes.${type}`))
  );

  const companyPlanChoices = (choicesType: CompanyPlanChoicesTypes) => {
    const listTypeKey = `key${choicesType}`;
    if (companyPlanChoicesContainer[listTypeKey]) {
      return companyPlanChoicesContainer[listTypeKey];
    }

    let selectedOptions: string[] = [];
    let planTypesResource = '';
    switch (choicesType) {
      case CompanyPlanChoicesTypes.Full:
        selectedOptions = [
          COMPANY_PLAN_TYPES.P210,
          COMPANY_PLAN_TYPES.P310,
          COMPANY_PLAN_TYPES.P410,
          COMPANY_PLAN_TYPES.P450,
          COMPANY_PLAN_TYPES.P510
        ];
        planTypesResource = 'resources.affiliations.fields.companyPlanTypes.';
        break;
      case CompanyPlanChoicesTypes.NotP8360AndP8430AndP8260:
        selectedOptions = [
          CORPORATIVE_PLAN_TYPES.P8260,
          CORPORATIVE_PLAN_TYPES.P8360,
          CORPORATIVE_PLAN_TYPES.P8430
        ];
        planTypesResource = 'resources.affiliations.fields.corporativePlantypes.';
        break;
      default:
        // case unspected. Alert.
        // eslint-disable-next-line no-console
        console.warn('Value for choicesType unspected.');
        return [];
    }

    companyPlanChoicesContainer[listTypeKey] = Object.values(selectedOptions)
      // .filter(type => !filterOutOptions.includes(type))
      .map(type => newInputSelectorItem(type, t(`${planTypesResource}${type}`)));

    return companyPlanChoicesContainer[listTypeKey];
  };

  const applicationTypesChoicesNoDDJJ = () => {
    let filterOutOptions: string[] = [];
    filterOutOptions = [APPLICATION_TYPES.DDJJ];
    const applicationTypes = Object.values(APPLICATION_TYPES)
      .filter(type => !filterOutOptions.includes(type))
      .map(type => newInputSelectorItem(type, t(`resources.affiliations.fields.applicationTypes.${type}`)));
    return applicationTypes;
  };

  const applicationTypesChoicesNoOTHERS = (applicationTypes: any) => {
    let filterOutOptions: string[] = [];
    filterOutOptions = [APPLICATION_TYPES.OTHERS];
    const applicationTys = Object.values(applicationTypes)
      .filter((type: any) => !filterOutOptions.includes(type.id))
      .map((type: any) =>
        newInputSelectorItem(type.id, t(`resources.affiliations.fields.applicationTypes.${type.id}`))
      );
    return applicationTys;
  };

  const getApplicationTypesChoices = (applicationTypes: string[]) =>
    Object.values(APPLICATION_TYPES)
      .filter(type => applicationTypes.includes(type))
      .map(type => newInputSelectorItem(type, t(`resources.affiliations.fields.applicationTypes.${type}`)));

  const applicationSubTypesChoicesTurnnedOff = ({
    ENABLED_DOWN,
    ENABLED_CHANGE_PLAN,
    ENABLED_SPECIAL_PLAN,
    ENABLED_PLURIEMPLEO,
    ENABLED_SUMATORIA_APORTES
  }: {
    ENABLED_DOWN: boolean;
    ENABLED_CHANGE_PLAN: boolean;
    ENABLED_SPECIAL_PLAN: boolean;
    ENABLED_PLURIEMPLEO: boolean;
    ENABLED_SUMATORIA_APORTES: boolean;
  }) => {
    const filterOutOptions: string[] = [];
    if (!ENABLED_DOWN) {
      filterOutOptions.push(APPLICATION_SUBTYPES.BAJA);
    }
    if (!ENABLED_CHANGE_PLAN) {
      filterOutOptions.push(APPLICATION_SUBTYPES.CAMBIO_DE_PLAN);
    }
    if (!ENABLED_SPECIAL_PLAN) {
      filterOutOptions.push(APPLICATION_SUBTYPES.PLAN_ESPECIAL);
    }
    if (!ENABLED_PLURIEMPLEO) {
      filterOutOptions.push(APPLICATION_SUBTYPES.PLURIEMPLEO);
    }
    if (!ENABLED_SUMATORIA_APORTES) {
      filterOutOptions.push(APPLICATION_SUBTYPES.SUMATORIO_DE_APORTES);
    }

    const applicationTys = Object.values(APPLICATION_SUBTYPES)
      .filter((type: any) => !filterOutOptions.includes(type))
      .map((type: any) =>
        newInputSelectorItem(type, t(`resources.affiliations.fields.applicationSubtypes.${type}`))
      );
    return applicationTys;
  };

  const increasePlanAllowedChoices = Object.values(INCREASE_PLAN_ALLOWED_TYPES).map(type =>
    newInputSelectorItem(type, t(`resources.affiliations.fields.increasePlanAllowedTypes.${type}`))
  );

  const isPaymentResponsibleChoices = Object.values(IS_PAYMENT_RESPONSIBLE_TYPES).map(type =>
    newInputSelectorItem(type, t(`resources.affiliations.fields.isPaymentResponsibleTypes.${type}`))
  );

  const promoterRoleChoices = Object.values(ROLE_TYPES)
    .map(type => {
      if (type === ROLE_TYPES.SuperAdmin) {
        return null;
      }
      return newInputSelectorItem(type, t(`resources.promoters.fields.rolTypes.${type}`));
    })
    .filter(item => item !== null) as InputSelectorItemType[];

  const promoterPickedRole = (type: any) => {
    const { role, subroles } = type;
    // return `resources.promoters.fields.rolTypes.${type}`;
    if (subroles.length >= 1) {
      if (role === 'superadmin') {
        return `resources.promoters.fields.rolTypes.${ROLE_TYPES.SuperAdmin}`;
      }
      if (role === 'admin') {
        return `resources.promoters.fields.rolTypes.${ROLE_TYPES.Adminstrador}`;
      }
      if (role === 'regular' && subroles.includes('analista') && subroles.includes('osde')) {
        return 'Usuario promotor';
      }
      if (subroles.includes('analista')) {
        return `resources.promoters.fields.rolTypes.${ROLE_TYPES.Analista}`;
      }
    }
    return `resources.promoters.fields.rolTypes.${role}`;
  };

  const permisoOSDEChoices = Object.values(PERMISO_OSDE_CHOICES).map(type =>
    newInputSelectorItem(type, t(`resources.promoters.fields.permisoOSDEChoices.${type}`))
  );

  const permisoBinariaChoices = Object.values(PERMISO_BINARIA_CHOICES).map(type =>
    newInputSelectorItem(type, t(`resources.promoters.fields.permisoOSDEChoices.${type}`))
  );

  const filialesChoices = Object.values(FILIALES_LIST).map(type =>
    newInputSelectorItem(type, t(`resources.promoters.fields.filialesList.${type}`))
  );

  const filialPickedChoice = (type: string) => `resources.promoters.fields.filialesList.${type}`;

  const capChoices = (filialCode: string) =>
    Object.values(CAP_LIST[filialCode as keyof typeof CAP_LIST]).map(capIdInFilial =>
      newInputSelectorItem(
        capIdInFilial,
        t(`resources.promoters.fields.capsList.${filialCode}-${capIdInFilial}`)
      )
    );

  const capPickedChoice = (filialCode: string, capCode: string) =>
    `resources.promoters.fields.capsList.${filialCode}-${capCode}`;

  return {
    documentChoices,
    affiliationTypeChoices,
    applicationTypeChoices,
    applicationSubtypeChoices,
    planTypeChoices,
    hiringModeChoices,
    increasePlanAllowedChoices,
    isPaymentResponsibleChoices,
    companyPlanChoices,
    promoterRoleChoices,
    promoterPickedRole,
    permisoOSDEChoices,
    permisoBinariaChoices,
    filialesChoices,
    filialPickedChoice,
    capChoices,
    capPickedChoice,
    applicationTypesChoicesNoDDJJ,
    applicationTypesChoicesNoOTHERS,
    getApplicationTypesChoices,
    applicationSubTypesChoicesTurnnedOff
  };
};

export default useListProviders;
