export const AFFILIATION_REQUEST_STATUS = {
  INITIAL_EMAIL_PENDING: 'initial_email_pending',
  INITIAL_EMAIL_SENT: 'initial_email_sent',
  FORM_LINK_OPENED: 'form_link_opened',
  PENDING_USER_VALIDATION: 'pending_user_validation',
  PENDING_PROMOTER_VALIDATION: 'pending_promoter_validation',
  PENDING_PROSPECT_VALIDATION: 'pending_prospect_validation',
  FINISHED: 'finished',
  REVISION: 'revision',
  USER_REJECTED: 'user_rejected',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  SAP: 'sap',
  SAP_RPA: 'sap_rpa',
  ZCAR: 'zcar',
  ENABLED: 'enabled'
};

export const SOURCE_APPICATION = {
  CONSOLA: 'CONSOLA',
  LINK: 'LINK',
  AUTOGESTION: 'AUTOGESTION'
};

export const AFFILIATION_REQUEST_STATUS_OSD = {
  INITIAL_EMAIL_PENDING: 'initial_email_pending',
  INITIAL_EMAIL_SENT: 'initial_email_sent',
  FORM_LINK_OPENED: 'form_link_opened',
  // PENDING_USER_VALIDATION: 'pending_user_validation',
  PENDING_PROMOTER_VALIDATION: 'pending_promoter_validation',
  FINISHED: 'finished',
  REVISION: 'revision',
  USER_REJECTED: 'user_rejected',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  SAP: 'sap',
  ZCAR: 'zcar',
  ENABLED: 'enabled'
};

export const AFFILIATION_TYPES = {
  DIRECT: 'direct',
  DIRECT_GROUP: 'direct_group',
  MANDATORY: 'mandatory',
  MONOTAX: 'monotax',
  AGREEMENT: 'agreement',
  COMPLEMENT: 'complement'
};

export const PLAN_TYPES = {
  BINARIO: 'Binario',
  CORPORATIVO: 'Corporativo'
};

export const APPLICATION_TYPES = {
  FULL: 'full_application',
  F1: 'f1_application',
  BENEFICIARY: 'beneficiary_application',
  DDJJ: 'ddjj',
  OTHERS: 'others'
};

export const APPLICATION_SUBTYPES = {
  CAMBIO_DE_CONDICION_IVA: '0031',
  CAMBIO_DE_PLAN: '0049',
  BAJA: '0050',
  PLURIEMPLEO: '0005-A',
  SUMATORIO_DE_APORTES: '0005-B',
  TRASPASOS_JERARQUIZACION: '0037',
  CAMBIO_GRUPO_DIRECTO_EMPRESA: '0052',
  LICENCIAS_SUBSIDIOS: '0008',
  SUBCIDIO_POR_FALLECIMIENTO: '0054',
  PLAN_ESPECIAL: '',
  CAMBIO_EMPLEADOR_APORTE_LEY: '0051',
  PLAN_6030: '0053'
};

export const DOCUMENT_TYPES = {
  DNI: 'dni',
  PASSPORT: 'passport',
  PASSPORT_SAP: 'pas',
  CUIL: 'cuil'
};

export const HIRING_MODE_TYPES = {
  PLA: 'PLA',
  APO: 'APO'
};

export const CORPORATIVE_PLAN_TYPES = {
  P8360: '8-360',
  P8430: '8-430',
  P8260: '8-260'
};

export const COMPANY_PLAN_TYPES = {
  P210: '210',
  P310: '310',
  P410: '410',
  P450: '450',
  P510: '510'
};

export const INCREASE_PLAN_ALLOWED_TYPES = {
  true: 'true',
  false: 'false'
};

export const IS_PAYMENT_RESPONSIBLE_TYPES = {
  true: 'true',
  false: 'false'
};

export const DNI_OF_CUIL_LENGTH = 10;
export const DNI_OF_CUIL = 2;

export const STATUS_200 = 200;
export const STATUS_201 = 201;
export const STATUS_400 = 400;
export const STATUS_404 = 404;
export const STATUS_503 = 503;
